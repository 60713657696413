<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add" icon="el-icon-plus">新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
        <el-button :disabled="multiSelection.length !== 1" icon="el-icon-document-copy" size="small" type="info" @click="dialogVisible = true">复制 </el-button>
        <el-button type="success" size="small" @click="exportList" :disabled="!btn.export" icon="el-icon-top-right">导出</el-button>
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="请假开始日期"
          end-placeholder="请假结束日期"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDequsNow()">搜索</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <el-dialog title="复制" :visible.sync="dialogVisible" style="margin-top: 10%" width="25%">
        <div class="vd_dio">
          <div>请输入复制条数：</div>
          <el-input v-model="inputDialog" placeholder="请输入条数" size="small" @input="inputDialog = helper.keepTNum(inputDialog)"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="closeDiao()">取 消</el-button>
          <el-button size="mini" type="primary" @click="doCopy()">确 定</el-button>
        </span>
      </el-dialog>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`leav_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @row-dblclick="dbClickJp"
        @getTableData="getDequsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import { leavAPI } from '@api/modules/leav';
import pubPagination from '@/components/common/pubPagination';
import options from '@/views/component/common/options';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { debounce } from 'lodash';
import { downloadFile } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';
export default {
  name: 'LeavList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'leav_cptt_name', label: '公司名称', input: false, itemType: 'input', widthAuto: true },
        { prop: 'leav_dept_name', label: '部门名称', itemType: 'select', options: [], widthAuto: true },
        { prop: 'leav_dept_team_name', label: '小组名称', input: false, itemType: 'input', widthAuto: true },
        { prop: 'leav_stff_name', label: '员工姓名', itemType: 'input', widthAuto: true },
        { prop: 'leav_type', label: '类型', itemType: 'select', options: [], widthAuto: true },
        {
          prop: 'leav_start_date',
          label: '开始日期',
          itemType: 'date',
          widthAuto: true,
          formatter: val => {
            return this.helper.toStringDay(val);
          }
        },
        {
          prop: 'formatLeavType',
          label: '时长',
          input: false,
          itemType: 'input',
          widthAuto: true
        },
        {
          prop: 'create_time',
          label: '录入时间',
          input: false,
          itemType: 'date',
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'leav_end_date',
          label: '结束日期',
          input: true,
          itemType: 'date',
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'status',
          label: '在批状态',
          itemType: 'select',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ],
          widthAuto: true
        }
      ],
      loadFlag: true,
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      leavTypeGroupOpt: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      dialogFormVisible: false,
      btn: {},
      dialogVisible: false,
      inputDialog: 1
    };
  },
  created() {
    // this.initData();
  },
  mounted() {
    this.initData();
    this.getLeavType(true);
    this.getDeptV1(true);
  },
  watch: {
    $route(to, from) {
      if (from.path === '/leav_add' || from.path === '/leav_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getLeavsList();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getLeavsList();
    }, 1000),
    //获取请假信息列表
    getLeavsList() {
      let searchForm = this.$refs.multiTable.searchForm;
      this.loadFlag = true;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      get(leavAPI.getLeavs, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.tableData.forEach(item => {
            item.formatLeavType = this.formatLeavDate(item);
            item.leav_type = this.formatLeavType(item);
          });
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取cpttId
    getCpttV1(flag) {
      if (flag === true && this.cpttGroupOpt.length === 0) {
        get(cpttAPI.getAllCpttsV1).then(res => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
            this.tableProperties[2].options = Array.from(this.deptGroupOpt, ({ dept_name, dept_id }) => {
              return { value: dept_id, label: dept_name };
            });
          }
        });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams).then(res => {
          if (res.data.code === 0) {
            this.deptTeamsGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取请假类型
    getLeavType(flag) {
      if (flag === true && this.leavTypeGroupOpt.length === 0) {
        this.leavTypeGroupOpt = options.leavOpts;
        this.tableProperties[5].options = this.leavTypeGroupOpt;
      }
    },
    // 请假类型
    formatLeavType(row) {
      switch (row.leav_type) {
        case 0:
          return '增加调休';
        case 1:
          return '调休';
        case 2:
          return '年假';
        case 3:
          return '事假';
        case 4:
          return '病假';
        case 5:
          return '产假';
        case 6:
          return '婚假';
        case 7:
          return '丧假';
        case 8:
          return '工伤';
        case 9:
          return '陪产';
        case 10:
          return '旅游假';
        case 11:
          return '产检假';
        case 12:
          return '本市外勤(上海)';
        case 13:
          return '外省市出差';
        case 14:
          return '外省市外勤';
      }
    },
    formatLeavDate(row) {
      if (row.leav_type === 0) {
        const leavDate = row.leav_hour_diff + '小时' + '(添加调休)';
        return leavDate;
      } else if (row.leav_type === 1) {
        const leavDate = row.leav_hour_diff + '小时';
        return leavDate;
      } else {
        if (row.leav_hour_diff) {
          const leavDate = row.leav_hour_diff + '小时';
          return leavDate;
        } else {
          const leavDate = row.leav_day_diff + '天';
          return leavDate;
        }
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.leav_start_date);
    },
    formatUpdate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.getLeavsList();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getLeavsList();
    },
    //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      const personal = this.$cookies.get('userInfo').personal;
      this.jump(`/leav_add`, {
        key: UrlEncode.encode(JSON.stringify({ perm_id: permId, personal: personal }))
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/leav_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.leav_id
          })
        )
      });
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 选未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    // 复制按钮
    doCopy() {
      this.dialogVisible = false;
      this.$confirm('确定复制' + this.inputDialog + '条？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(leavAPI.copyLeavs, {
            leav_id: this.multiSelection[0].leav_id,
            copy_num: this.inputDialog
          })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                this.changePageSearch(1);
                this.$refs.pubPagination.retOne();
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                // this.initData()
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          this.dialogVisible = true;
        });
    },
    // 关闭复制条数弹框
    closeDiao() {
      this.dialogVisible = false;
      this.$message({
        type: 'info',
        message: '已取消输入'
      });
    },
    //请假信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.leav_id);
          });
          post(leavAPI.deleteLeavByIds, { leav_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    //导出
    exportList() {
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      getNoCatch(leavAPI.exportLeavListV1, searchForm).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: '请假信息列表' });
        this.$message({ message: '导出成功', type: 'success' });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_dio {
  display: flex;
  align-items: center;
  .el-input {
    width: 70%;
  }
}
</style>
